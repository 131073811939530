@import "variables";

@font-face {
  font-family: SFProDisplay;
  src: url("../public/fonts/sf-pro-display-font/SF-Pro-Display-Regular.ttf");
}

body {
  margin: 0;
  font-size: 12px;
  font-family: SFProDisplay;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.036px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: $textColor;
}

h2 {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.066px;
}

.only-desktop {
  @media (max-width: $breakpointTablet) {
    display: none;
  }
}

.only-tablet {
  @media (min-width: $breakpointTablet) {
    display: none;
  }
}

.hidden {
  display: none !important;
}
