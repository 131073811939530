$textColor: #000;

$primaryColor: #e9e9e9;

$defaultSpace: 20px;
$defaultBorderRadius: 15px;

// Media queries
$breakpointMobile: 480px;
$breakpointTablet: 768px;
