@import "variables";

.error {
  background: red;
  color: white;
  padding: 20px;
  display: inline-block;
  font-size: 3em;
}

.root {
  display: flex;
  gap: $defaultSpace / 2;
  padding: 0 $defaultSpace / 2;

  @media (max-width: $breakpointTablet) {
    flex-direction: column;
  }

  .fill-space {
    width: 100%;
    display: inline-block;
    background: red;
  }

  .box {
    background: $primaryColor;
    border-radius: $defaultBorderRadius;
    padding: $defaultSpace;
    margin-top: $defaultSpace / 2;
    box-sizing: border-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    padding-bottom: 66.6%;

    h2,
    p {
      margin: 0;
      margin-bottom: 10px;
    }
  }

  .btn-about {
    padding: $defaultSpace / 2 $defaultSpace;
    width: fit-content;
    margin-top: $defaultSpace;
    display: inline-block;
  }

  .media-links {
    display: inline-block;
    margin-left: 20px;
    margin-top: $defaultSpace;

    a {
      margin: 0 5px;
      font-size: 1.2em;
    }
  }

  .project-list-container {
    justify-content: start;
    min-width: 340px;
    width: 340px;
    overflow-y: scroll;
    height: 100vh;

    @media (max-width: $breakpointTablet) {
      height: auto;
      overflow-y: visible;
      min-width: 0;
      width: calc(100% - $defaultSpace / 2);
    }

    .contact-info {
      h2 {
        font-size: 22px;
        font-weight: normal;
      }

      img {
        height: 80px;
      }
    }

    .project-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $primaryColor;
      padding: 13px 16px;
      margin: 10px 0;
      cursor: pointer;

      :first-child {
        padding-right: 10px;

        .show-more {
          margin: 0;
          margin-bottom: 15px;
          color: #8d8d8d;
          display: block;
        }

        .title {
          font-weight: bolder;
          margin: 0;
          margin-bottom: 5px;
          color: #000;
        }

        .description {
          margin: 0;
          color: #3f3f3f;
        }
      }

      :last-child {
        justify-content: center;
        padding: 0;

        .thumbnail {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: $defaultBorderRadius - 5px;
        }
      }
    }
  }

  .router-container {
    justify-content: start;
    flex-grow: 1;
    overflow-y: scroll;
    height: 100vh;

    @media (max-width: $breakpointTablet) {
      height: auto;
      overflow-y: visible;
    }
  }
}

.about-btn-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.about {
  padding: $defaultSpace 0;

  .box {
    background: $primaryColor;
    padding: $defaultSpace;
    border-radius: $defaultBorderRadius;
    flex-grow: 1;
  }

  .margin-left {
    margin-left: 10px;
  }

  .introduction,
  .mexico-story,
  .mexico-images,
  .calgary-story {
    display: flex;

    @media (max-width: $breakpointTablet) {
      flex-direction: column;

      .box:not(.text) {
        padding-bottom: 80% !important;
      }

      .box.animation {
        padding-bottom: 120% !important;
      }

      .margin-left {
        margin-left: 0;
      }
    }
  }

  .introduction {
    > :nth-child(1) {
      .contact-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
        }

        .contact-word {
          font-weight: bolder;
          margin-bottom: 5px;
        }

        .media-links {
          font-size: 2em;

          > * {
            margin-left: 10px;
          }
        }
      }
    }

    > :nth-child(2) {
      min-width: 50%;
      background-image: url("../public/images/about-me/about-0.gif");
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: center;

      @media (max-width: $breakpointTablet) {
        background-size: 300%;
      }
    }
  }

  .mexico-story {
    > :nth-child(1) {
      padding-bottom: 38%;
      min-width: 50%;
      background-image: url("../public/images/about-me/about-1.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: center;
      background-color: #ffc63b;
    }

    > :nth-child(2) {
      padding-bottom: 38%;
      min-width: 50%;
      background-image: url("../public/images/about-me/about-2.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: center;
      // background-color: #ffc63b;
    }
  }

  .mexico-images {
    > :nth-child(1) {
      min-width: 60%;
      padding-bottom: 38%;
      background-image: url("../public/images/about-me/about-3.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: center;
    }

    > :nth-child(2) {
      padding-bottom: 38%;
      background-image: url("../public/images/about-me/about-4.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: center;
    }
  }

  .calgary-story {
    > :nth-child(1) {
      padding-bottom: 38%;
      background-image: url("../public/images/about-me/about-5.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-color: $primaryColor;
      background-position: center;
    }

    > :nth-child(2) {
      min-width: 40%;
      padding-bottom: 38%;
      background-image: url("../public/images/about-me/about-6.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-color: $primaryColor;
      background-position: center;
    }
  }

  .patineta {
    > :nth-child(1) {
      padding-bottom: 40%;
      background-image: url("../public/images/about-me/about-7.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: center;
      background-color: #ffc63b;
    }
  }
}

.home {
  margin-top: 70px;
  margin-bottom: $defaultSpace / 2;

  @media (max-width: $breakpointTablet) {
    display: none;
  }

  video {
    width: 100%;
  }

  h2 {
    margin: 0 !important;
  }

  .contact-devil {
    width: 150%;
    display: flex;
    gap: $defaultSpace/2;
    align-items: center;
    justify-content: space-between;

    img {
      width: 42px;
    }
  }

  .contact-info {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
    }

    .contact-word {
      font-weight: bolder;
      margin-bottom: 5px;
    }

    .media-links {
      font-size: 2em;

      > * {
        margin-left: 10px;
      }
    }
  }

  .video-container {
    width: 100%;
    height: auto;
    // padding-bottom: 56.25%;
    border-radius: $defaultBorderRadius;
    overflow: hidden;
  }

  .footer {
    display: flex;
    gap: $defaultSpace / 2;
  }

  .box {
    padding-bottom: $defaultSpace;
  }
}

.show-less {
  margin-top: $defaultSpace / 2;
  background: $primaryColor;
  padding: $defaultSpace / 2 $defaultSpace;
  display: inline-block;
  border-radius: $defaultBorderRadius;

  @media (min-width: $breakpointTablet) {
    display: none;
  }
}

.credits {
  p {
    margin-bottom: 0 !important;
  }
}

.project-text-box {
  @media (max-width: $breakpointTablet) {
    width: 100% !important;
  }
}
